import axios from "axios"

export const host = "https://api.als-info.ru/"
// export const image_host = "https://image.als-info.ru/"
export const image_host = "https://s3.twcstorage.ru/b21703cd-469bf917-7622-4899-8f87-a510f3f232a3/"

export interface Order{
    pk: number
    status: string
    user: {
        pk: number
        email: string
    }
    createDate: string
    cost: number
    finalCost: number
    order_item: ItemInOrder[]
}
export interface ItemInOrder{
    pk: number
    cost: number 
    finalCost: number
    module: {
        pk: number
        title: string
    }
}
export interface Buyer {
    pk: number
    email: string
    name: string
    surname: string
    minName: string
    phone: string
}
export interface Group{
    pk: number
    title: string
    desc: string
    users: BuyerItem[]
}
export interface Excel {
    name: string
    surname: string
    minName: string
    email: string
    phone: string
    password: string
}
export interface Media {
    pk: number
    image_name: string
}
export interface Setting {
    pk: number
    title: string
    value: string
}
export interface Mail {
    pk: number
    title: string
    desc: string
    field: string
}

export interface Slide {
    pk: number
    title: string
    desc: string
    link: string
    image_link: string
    sort: number
}

export interface CourseItemImp{
    pk: number
    sort: number
    title: string
    active: boolean
    cost: number
    finalCost: number
    miniDesc: string
    previewUID: string
    previewActive: boolean
    descPercent: number
    discountDesc: string
}
export interface Teacher{
    pk: number
    title: string 
    desc: string 
    image: string
}
export interface Module{
    pk: number
    title: string
    active: boolean
    course: number
    miniDesc: string
    cost: number
    finalCost: number
    sort: number
}
export interface Lessons{
    pk: number
    title: string
    active: boolean 
    miniDesc: string
    sort: number
}
export interface Review{
    pk: number
    fio: string
    course: {
        pk: number
        title: string,
    }
    createDate: string
    review: string
}
export interface TestingItem{
    pk: number
    user: {
        pk: number
        email: string
    }
    lesson: {
        pk: number
        title: string
    }
    content: string
    score: string
    test: []
}


export class User{
    userToken: string = ""
    
    constructor(){
        if(localStorage.getItem("userLogin")){
            const userProps = JSON.parse(localStorage.getItem("userLogin") || "")
            this.userToken = userProps.userToken
        }
    }

    async Logout(){
        localStorage.setItem("userLogin", JSON.stringify({
            "userToken": "",
        }))
        this.userToken = ""
    }

    async Login(email: string, password: string){
        let data = {
            error: true,
            msg: "error"
        }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(password))
        return {
            "error": true,
            "msg": "Пароль указан неверно"
        }
        
        const Hthis = this 
        await axios.post(`${host}login/`, {
            email: email,
            password: password
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {  
            console.log(res.data)
            if(res.data.error == false){
                localStorage.setItem("userLogin", JSON.stringify({
                    "userToken": res.data.data.userToken,
                }))
                Hthis.userToken = res.data.data.userToken
            }
            
            data = {
                error: res.data.error,
                msg: res.data.msg
            }
        }).catch(function(error){
            console.log(error)
        })
        return data

    }
}

export class GroupItem implements Group {
    pk: number
    title: string
    desc: string
    users: BuyerItem[] = []
    userCount: number = 0
    constructor(item: Group, userCount = 0){
        this.pk = item.pk
        this.title = item.title
        this.desc = item.desc
        this.userCount = userCount
    }

    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}group/${Hthis.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.pk = res.data.pk
            Hthis.title = res.data.title
            Hthis.desc = res.data.desc
            Hthis.users = []

            res.data.users.forEach((item: Buyer, index: number) => {
                Hthis.users.push(new BuyerItem({...item}))
            }) 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

    async updateData(token: string, title: string, desc: string){
        let data = "Данные обновлены"
        await axios.put(`${host}group/${this.pk}/`, {
            title: title,
            desc: desc
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            data = "Ошибка сохранения данных"
            console.log(error)
        })
        return data
    }
    
    async delData(token: string){
        let data = "Группа удалена"
        await axios.delete(`${host}group/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            data = "Ошибка удаление группы"
            console.log(error)
        })
        return data
    }

    async setUser(token: string, list: string){
        let data = "Пользователь добавлен в группу ()"
        const users = list.split(';')

        if(users[users.length-1] == "")
            users.pop()

        await axios.post(`${host}group-users/`,{
            email: users
        },{
            params: {
                group: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(function(res){
        
            if(res.data.error == false){
                data = `Пользователь добавлен в группу (${res.data.data.count})`
            }else{
                data = "Ошибка добавления пользователя в группу"
            }
            console.log(res.data.data.count)
        }).catch(function(error){
            console.log(error)
            data = "Ошибка удаления пользователя из группы"
        })

        return data
    }

    async delUser(token: string, user: number){
        let data = "Пользователь удален из группы"
        axios.delete(`${host}group-users/`,{
            params: {
                user: user,
                group: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(function(res){
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
            data = "Ошибка удаления пользователя из группы"
        })
        return data
    }
}

export class Groups{
    list: GroupItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async getData(token: string, page: number, search: string){
        this.page = page
        const Hthis = this
        await axios.get(`${host}group/`, {
            params: {
                'page': Hthis.page,
                'search': search
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: Group, index: number) => {
                Hthis.list.push(new GroupItem({...item}, item.users.length))
            }) 
        }).catch(function(error){
            console.log(error)
        })
    }

    async setData(token: string, data: {title: string, desc: string}){
        let msg = "Группа успешно добавлена"
        await axios.post(`${host}group/`, {
            ...data
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
            msg = "Ошибка добавления группы"
        })

        return msg
    }
}

export class BuyerItem implements Buyer {
    pk: number
    email: string
    name: string
    surname: string
    minName: string
    phone: string

    constructor(item: Buyer = {
        pk: 0,
        email: "",
        name: "",
        surname: "",
        minName: "",
        phone: ""
    }){
        this.pk = item.pk
        this.email = item.email
        this.name = item.name
        this.surname = item.surname
        this.minName = item.minName
        this.phone = item.phone
    }


    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}user/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.pk = res.data.pk
            Hthis.email = res.data.email
            Hthis.name = res.data.name
            Hthis.surname = res.data.surname
            Hthis.minName = res.data.minName
            Hthis.phone = res.data.phone
        }).catch(function(error){
            console.log(error)
        })
    }
}

export class Buyers{
    list: BuyerItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async setData(token: string, data: Excel[]){
        let userCount = 0
        data = data.filter((item, index) => {
            if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(item.password))
                return false

            if(item.email == "" || item.minName == "" || item.name == "" || item.password == "" || item.phone == "" || item.surname == "")
                return false

            return true
        })
        userCount = data.length

        if(data.length){
            const Hthis = this
            await axios.post(`${host}user/`, {
                users: data
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }).then((res) => { 
                console.log(res.data)
            }).catch(function(error){
                console.log(error)
            })
        }
        return `Добавлено пользователей: ${userCount}`
    }

    async getData(token: string, page: number, search: string, all = false){
        this.page = page
        const Hthis = this
        await axios.get(`${host}user/`, {
            params: {
                'page': Hthis.page,
                'search': search,
                'allUser': all
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: Buyer, index: number) => {
                Hthis.list.push(new BuyerItem({...item}))
            }) 
        }).catch(function(error){
            console.log(error)
        })
    
        return Hthis.list
    }

}

export class OrderItem implements Order {
    pk: number
    status: string
    user: {
        pk: number
        email: string
    }
    createDate: string
    cost: number
    finalCost: number
    
    order_item: ItemInOrder[]

    constructor(item: Order){
        this.pk = item.pk
        this.status = item.status
        this.user = item.user
        this.createDate = item.createDate
        this.cost = item.cost
        this.finalCost = item.finalCost
        this.order_item = []
    }

    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}order/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            this.pk = res.data.pk
            this.status = res.data.status
            this.createDate = res.data.createDate
            this.cost = res.data.cost
            this.finalCost = res.data.finalCost
            this.order_item = res.data.order_item
            
        }).catch(function(error){
            console.log(error)
        })
    }
}

export class MediaItem implements Media {
    pk: number
    image_name: string

    constructor(item: Media){
        this.pk = item.pk
        this.image_name = item.image_name
    }

    async delData(token: string){
        let data = "Файл удален"
        await axios.delete(`${host}media/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            data = "Ошибка удаление файла"
            console.log(error)
        })
        return data
    }
}

export class MediaManager{
    list: MediaItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async getData(token: string, page: number){
        this.page = page
        const Hthis = this
        await axios.get(`${host}media/`, {
            params: {
                'page': Hthis.page
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: Media, index: number) => {
                Hthis.list.push(new MediaItem({...item}))
            }) 
        }).catch(function(error){
            console.log(error)
        })
    }

    async setData(token: string, image: any){
        await axios.post(`${host}media/`, {
            file: image
        },{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
        return "Файл успешно загружен"
    }
}

export class Orders{
    list: OrderItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async getData(token: string, page: number, search: string){
        this.page = page
        const Hthis = this
        console.log("teetetetet")
        await axios.get(`${host}order/`, {
            params: {
                'page': Hthis.page,
                'search': search
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: Order, index: number) => {
                Hthis.list.push(new OrderItem({...item}))
            }) 
        }).catch(function(error){
            console.log(error)
        })
    }
}

export class Settings{
    list: Setting[]

    constructor(){
        this.list = []
    }

    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}setting/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            res.data.forEach((item: Setting, index: number) => {
                Hthis.list.push(item)
            }) 
        }).catch(function(error){
            console.log(error)
        })
        return this.list
    }

    async setData(token: string){
        const Hthis = this
        await axios.post(`${host}setting/`, {
            setting: Hthis.list
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
        return "Данные успешно сохранены"
    }
}


export class SlideItem implements Slide{
    pk: number
    title: string
    desc: string
    link: string
    image_link: string
    sort: number

    constructor(item: Slide){
        this.pk = item.pk
        this.title = item.title
        this.desc = item.desc
        this.link = item.link
        this.image_link = item.image_link
        this.sort = item.sort
    }

    async Delete_item(token: string){
        let data = "Файл удален"
        await axios.delete(`${host}slider-admin/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            data = "Ошибка удаление файла"
            console.log(error)
        })
        return data
    }
}

export class Slider{
    list: SlideItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async getData(token: string, page: number){
        this.page = page
        await axios.get(`${host}slider-admin/`, {
            params: {
                page: page
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            this.list = []
            this.pageCount = res.data.pageCount
            console.log(res.data)
            res.data.data.forEach((item: Slide, index: number) => {
                this.list.push(new SlideItem(item))
            }) 
        }).catch(function(error){
            console.log(error)
        })
        return this.list
    }

    async createData(token: string, form: {}){
        await axios.post(`${host}slider-admin/`, form, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).catch(function(error){
            console.log(error)
        })
    }
}


export class MailsItem implements Mail{
    pk: number
    title: string
    desc: string
    field: string

    constructor(item: Mail){
        this.pk = item.pk
        this.title = item.title
        this.desc = item.desc
        this.field = item.field
    }

    async setData(token: string, file: any){
        await axios.put(`${host}mails/${this.pk}/`, {
            file: file
        },{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
        return "Файл успешно обновлен"
    }
}

export class Mails{
    list: MailsItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async getData(token: string, page: number, search: string){
        this.page = page
        const Hthis = this
        await axios.get(`${host}mails/`, {
            params: {
                search: search,
                page: page
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: Mail, index: number) => {
                Hthis.list.push(new MailsItem(item))
            }) 
        }).catch(function(error){
            console.log(error)
        })
        return this.list
    }
}


export class TeacherItem implements Teacher{
    pk: number
    title: string 
    desc: string 
    image: string

    constructor(item: Teacher){
        this.pk = item.pk
        this.title =item.title
        this.desc = item.desc
        this.image = item.image
    }

    async updateData(token: string, form: any){
        let data = "Преподаватель обновлен"
        await axios.put(`${host}teachers/${this.pk}/`, {
            ...form
        },{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            data = "Ошибка обновления преподавателя"
            console.log(error)
        })
        return data
    }

    async delData(token: string){
        let data = "Преподаватель удален"
        await axios.delete(`${host}teachers/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            data = "Ошибка удаления преподавателя"
            console.log(error)
        })
        return data
    }
}
export class Teachers{
    list: TeacherItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async getData(token: string, page: number, search: string){
        const Hthis = this
        await axios.get(`${host}teachers/`, {
            params: {
                search: search,
                page: page
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: Teacher, index: number) => {
                Hthis.list.push(new TeacherItem(item))
            }) 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

    async setData(token: string, data: any){
        let msg = "Преподаватель успешно добавлен"
        await axios.post(`${host}teachers/`, {
            ...data
        },{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
            msg = "Ошибка добавления преподавателя"
        })

        return msg
    }
}

export class Testing{ 
    list: TestingItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async getData(token: string, page: number, pk: number = 0){
        this.page = page
        const Hthis = this 

        await axios.get(`${host}testing-admin/`, {
            params: {
                page: page,
                pk: pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            this.list = []
            res.data.data.forEach((item: any, index: number) => {
                this.list.push({
                    ...item,
                    test: JSON.parse(item.test)
                })
            })
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }
}

export class LessonsItem implements Lessons{
    pk: number
    title: string
    active: boolean 
    miniDesc: string
    sort: number
    content: string = ""
    test: [{}] = [{}]

    constructor(item: Lessons = {pk: 0, title: "", active: false, miniDesc: "", sort: 0}){
        this.pk = item.pk
        this.title = item.title
        this.active = item.active
        this.miniDesc = item.miniDesc
        this.sort = item.sort
    }
    
    async UpdateData(token: string, form: any){
        let data = "Урок успешно обновлен"

        await axios.put(`${host}lessons-admin/${this.pk}/`, {
            sort: form.sort,
            title: form.title,
            miniDesc: form.miniDesc,
            content: form.content,
            test: JSON.stringify(form.test)
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
            data = "Ошибка обновления урока"
        })
        return data
    }

    async UpdateActive(token: string){
        axios.post(`${host}lessons-status/`,{}, {
            params: {
                pk: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        return "Статус модуля успешно изменен"
    }

    async getData(token: string){
        const Hthis = this

        console.log(this.pk)

        await axios.get(`${host}lessons-admin/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.pk = res.data.pk
            Hthis.sort = res.data.sort
            Hthis.title = res.data.title
            Hthis.miniDesc = res.data.miniDesc
            Hthis.content = res.data.content
            Hthis.test = JSON.parse(res.data.test)
        }).catch(function(error){
            console.log(error)
        })

    }
}
 
export class ModuleItem implements Module{
    list: LessonsItem[]
    pk: number
    sort: number
    title: string
    active: boolean
    course: number
    miniDesc: string
    cost: number
    finalCost: number

    constructor(item: Module = {pk: 0, title: "", active: false, course: 0, miniDesc: "", cost: 0, finalCost: 0, sort: 0}){
        this.list = []
        this.pk = item.pk
        this.title = item.title
        this.active = item.active
        this.course = item.course
        this.miniDesc = item.miniDesc
        this.cost = item.cost
        this.finalCost = item.finalCost
        this.sort = item.sort
    }

    async getData(token: string){
        const Hthis = this
        await axios.get(`${host}modules-admin/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.title = res.data.title
            Hthis.miniDesc = res.data.miniDesc
            Hthis.course = res.data.course
            Hthis.miniDesc = res.data.miniDesc
            Hthis.cost = res.data.cost
            Hthis.finalCost = res.data.finalCost
            Hthis.sort = res.data.sort

            Hthis.list = []

            res.data.lesson_module.forEach((item: Lessons, index: number) => {
                Hthis.list.push(new LessonsItem(item))
            })

            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

    async UpdateActive(token: string){
        axios.post(`${host}modul-status/`,{}, {
            params: {
                pk: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        return "Статус модуля успешно изменен"
    }

    async UpdateData(token: string, form: any){
        let data = "Модуль успешно обновлен"

        await axios.put(`${host}modules-admin/${this.pk}/`, {
            ...form,
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
            data = "Ошибка обновления модуля"
        })
        return data
    }

    async SetLesson(token: string, form: any){
        let data = "Урок успешно создан"
        axios.post(`${host}lessons-admin/`,{
            sort: form.sort,
            title: form.title,
            miniDesc: form.miniDesc,
            content: form.content,
            test: JSON.stringify(form.test)
        }, {
            params: {
                pk: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(function(responce){
            console.log(responce.data)
        })
        return data
    }
}

export class CourseItem implements CourseItemImp{
    list: ModuleItem[]
    pk: number
    sort: number
    title: string
    active: boolean
    cost: number
    finalCost: number
    miniDesc: string
    previewUID: string
    previewActive: boolean
    descPercent: number
    discountDesc: string
    certificate_image: string = "0"
    certificate_title: string = "0"
    certificate_description: string = "0"
    formLink: string = ""
    blueText: string = ""

    constructor(item: CourseItemImp = {pk: 0, sort: 0, title: "", active: false, cost: 0, finalCost: 0, miniDesc: "", previewUID: "", previewActive: false, descPercent: 0, discountDesc: ""}){
        this.list = []
        this.pk = item.pk
        this.sort = item.sort
        this.title = item.title
        this.active = item.active
        this.cost = item.cost
        this.finalCost = item.finalCost
        this.miniDesc = item.miniDesc
        this.previewUID = item.previewUID
        this.previewActive = item.previewActive
        this.descPercent = item.descPercent
        this.discountDesc = item.discountDesc
    }

    async UpdateActive(token: string){
        axios.post(`${host}courses-status/`,{}, {
            params: {
                pk: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        return "Статус курса успешно изменен"
    }

    async UpdatePreview(token: string){
        axios.post(`${host}courses-preview/`,{}, {
            params: {
                pk: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        return "Статус курса успешно изменен"
    }

    async GetData(token: string){
        let data = {
            "title": "",
            "miniDesc": "", 
            "duration": "",
            "type": "",
            "about": "",
            "content": "",
            "learn1": "",
            "learn2": "",
            "learn3": "",
            "learn4": "",
            "learn5": "",
            "learn6": "",
            "suitable_course": [
                {
                    "pk": 0,
                    "image": "",
                    "title": "",
                    "miniDesc": ""
                }
            ],
            "teachers": [],
            "descPercent": 0,
            "discountDesc": "",
            "certificate_image": "",
            "certificate_title": "",
            "certificate_description": "",
            "formLink": "",
            "blueText": ""
        }
        const Hthis = this
        await axios.get(`${host}courses-admin/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
            Hthis.pk = res.data.pk
            Hthis.sort = res.data.sort
            Hthis.title = res.data.title
            Hthis.active = res.data.active
            Hthis.cost = res.data.cost
            Hthis.finalCost = res.data.finalCost
            Hthis.miniDesc = res.data.miniDesc
            Hthis.previewUID = res.data.previewUID
            Hthis.previewActive = res.data.previewActive
            Hthis.descPercent = res.data.descPercent
            Hthis.discountDesc = res.data.discountDesc
            Hthis.list = []
            Hthis.certificate_image = res.data.certificate_image
            Hthis.certificate_title = res.data.certificate_title
            Hthis.certificate_description = res.data.certificate_description
            Hthis.formLink = res.data.formLink
            Hthis.blueText = res.data.blueText

            res.data.module_course.forEach((item: any, index: number) => {
                Hthis.list.push(new ModuleItem({...item}))
            })
            const learn = JSON.parse(res.data.learn)

            data = {
                ...res.data,
                learn1: learn.learn1,
                learn2: learn.learn2,
                learn3: learn.learn3,
                learn4: learn.learn4,
                learn5: learn.learn5,
                learn6: learn.learn6
            }
        }).catch(function(error){
            console.log(error)
        })

        return data
    }

    async SetModule(token: string, form: any){
        let data = "Модуль успешно создан"
        axios.post(`${host}modules-admin/`,{
            ...form
        }, {
            params: {
                pk: this.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(function(responce){
            console.log(responce.data)
        })
        return data
    }

    async UpdateData(token: string, form: any){
        let data = "Курс успешно обновлен"

        const learn = {
            learn1: form.learn1,
            learn2: form.learn2,
            learn3: form.learn3,
            learn4: form.learn4,
            learn5: form.learn5,
            learn6: form.learn6
        }

        form.formLink = form.formLink ? form.formLink : "-"
        
        await axios.put(`${host}courses-admin/${this.pk}/`, {
            ...form,
            learn: JSON.stringify(learn)
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
            data = "Ошибка создания курса"
        })
        return data 
    }

    async SetImage(token: string, image: any, details: boolean = false){
        axios.post(`${host}courses-image/`,{
            image: image
        }, {
            params: {
                pk: this.pk,
                details: `${details}`
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        }).then(function(response){

        })
        return "Фото курса успешно изменено"
    }

    async SetCertificates(token: string, image: any, details: boolean = false){
        axios.post(`${host}courses-certificates/`,{
            image: image
        }, {
            params: {
                pk: this.pk,
                details: `${details}`
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        }).then(function(response){

        })
        return "Фото сертификата успешно изменено"
    }
    
}

export class Courses{
    list: CourseItem[]
    page: number = 1
    pageCount: number = 1

    constructor(){
        this.list = []
    }

    async getData(token: string, page: number, search: string){
        const Hthis = this
        await axios.get(`${host}courses-admin/`, {
            params: {
                search: search,
                page: page
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: CourseItemImp, index: number) => {
                Hthis.list.push(new CourseItem(item))
            }) 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

    async setData(token: string, form: any){
        let data = "Курс успешно создан"

        const learn = {
            learn1: form.learn1,
            learn2: form.learn2,
            learn3: form.learn3,
            learn4: form.learn4,
            learn5: form.learn5,
            learn6: form.learn6
        }
           
        console.log(form)
        await axios.post(`${host}courses-admin/`, {
            ...form,
            learn: JSON.stringify(learn)
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
            data = "Ошибка создания курса"
        })
        return data
    }
}

export class ReviewItem implements Review{
    pk: number
    fio: string
    course: {
        pk: number
        title: string,
    }
    createDate: string
    review: string

    constructor(item: Review){
        this.pk = item.pk
        this.fio = item.fio
        this.course = item.course
        this.createDate = item.createDate
        this.review = item.review
    }

    async delData(token: string){
        let data = "Отзыв удален"
        await axios.delete(`${host}reviews/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            console.log(res.data)
        }).catch(function(error){
            data = "Ошибка удаления отзыва"
            console.log(error)
        })
        return data
    }


    async updateData(token: string, form: any){
        let data = "Отзыв успешно обновлен"
        axios.put(`${host}reviews/${this.pk}/`,{
            ...form
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(function(responce){
            console.log(responce.data)
        })
        return data
    }

}
export class Reviews{
    list: ReviewItem[]
    page: number = 1
    pageCount: number = 1
    
    constructor(){
        this.list = []
    }

    async getData(token: string, page: number, search: string){
        this.page = page
        const Hthis = this
        await axios.get(`${host}reviews/`, {
            params: {
                'page': Hthis.page,
                'search': search
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            Hthis.list = []
            Hthis.pageCount = res.data.pageCount
            res.data.data.forEach((item: Review, index: number) => {
                Hthis.list.push(new ReviewItem({...item}))
            }) 
        }).catch(function(error){
            console.log(error)
        })
    }

    async setData(token: string, form: any){
        let data = "Отзыв успешно создан"
        axios.post(`${host}reviews/`,{
            ...form
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(function(responce){
            console.log(responce.data)
        })
        return data
    }

}